<template>
  <div class="MaintenanceContent">
    <div>
      <p class="text-center">

        <a href="https://www.google.com/maps/place/Au+Vrac+Hasparren/@43.3835223,-1.3059975,17z/data=!3m1!4b1!4m5!3m4!1s0xd5131839c763795:0xe8b828323c1062ad!8m2!3d43.3835223!4d-1.3059975" target="_blank" class=" mt-5 btn bg-primary text-white hover:bg-green-200 hover:text-gray-700">
          Je veux y aller !
        </a>
      </p>
      <p class="text-center mt-4 mb-4">
        <ShopOpeningHours class="text-center"/>
      </p>
      <p class="text-center">
        Ouverture de la boutique en ligne : prochainement
      </p>
      <div class="flex flex-col items-center justify-center w-full h-full">
          <NewsletterSengridForm />
      </div>

    </div>
    <p class="text-center mt-4">
      <a href="tel:+33782052321" target="_blank">07 82 05 23 21</a>
      <br>
      <a href="mailto:contact@au-vrac.fr" target="_blank">contact@au-vrac.fr</a>
    </p>

    <footer class="bg-white dark:bg-gray-900 dark:text-gray-200">
      <div class="max-w-7xl mx-auto pb-2 pt-4 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          <div class="px-5 py-2">
            <span @click="showMentions = !showMentions" class="hover:cursor-pointer hover:underline text-base text-gray-500 hover:text-gray-900">
              Mentions légales
            </span>
          </div>
        </nav>
      </div>
      <SocialIcons />
      <CopyRights class="mt-4">
        &copy; 2022 Au Vrac Hasparren
        <br>
        <a class="text-gray-400" href="https://www.google.com/maps/place/Au+Vrac+Hasparren/@43.3835223,-1.3059975,17z/data=!3m1!4b1!4m5!3m4!1s0xd5131839c763795:0xe8b828323c1062ad!8m2!3d43.3835223!4d-1.3059975" target="_blank">17 rue Jean Lissar, 64240 Hasparren, France</a>
      </CopyRights>
    </footer>

    <TransitionRoot as="template" :show="showMentions">
      <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="showMentions = false">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-500 dark:bg-gray-800 dark:bg-opacity-80 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <div class="inline-block align-middle rounded-lg text-left overflow-hidden shadow-xl transform transition-all  md:align-middle  md:max-w-md md:w-full bg-white dark:bg-gray-800 dark:border-amber-50 dark:border-2 md:my-8">
              <MentionsContent/>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>
<script setup>
import NewsletterSengridForm from "~/components/forms/NewsletterSengridForm.vue";
import SocialIcons from "~/components/layout/SocialIcons.vue";
import CopyRights from "~/components/layout/CopyRights.vue";
import {ref} from 'vue';
import MentionsContent from "~/components/content/MentionsContent.vue";
import {TransitionRoot, Dialog, TransitionChild, DialogOverlay} from "@headlessui/vue";
import ShopOpeningHours from "~/components/content/ShopOpeningHours.vue";


const showMentions = ref(false)
</script>
