<template>
  <div class="ShopOpeningHours not-prose">
    <Spinner color="blue" v-if="pending"/>
    <p class="text-red-500" v-else-if="error">Les horaires d'ouverture ne sont pas disponible : {{error}}</p>
    <div v-else-if="data && !pending && !error" >
      <details class="terms" :open="opened">
        <summary class="my-2 cursor-pointer select-none focus:outline-1 focus:outline-dashed">Horaires d'ouverture du magasin</summary>
        <dl class="ml-1">
          <template v-for="(config, day) in data" :key="day">
            <dt>{{config.dayStr}} :</dt>
            <dd class="pb-2">
              <span v-if="!config || config.hours.length === 0">Fermé</span>
              <ul v-else class="ml-2">
                <li v-for="(hour, h) in config.hours" :key="day+'-'+h">{{hour.from}} - {{hour.to}}</li>
              </ul>
            </dd>
          </template>
        </dl>
      </details>
    </div>
    <div class="clear-both">&nbsp;</div>
  </div>
</template>

<script setup>
import Spinner from "~/components/Spinner.vue";
import {useApi} from "~/src/api";
import {onMounted, ref, toRef} from "vue";

const api = useApi();
const meta = ref(null);
const {data, pending, error, refresh} = await useAsyncData('load-opening-hours', () => api.openingHours());
const props = defineProps({
  open: {type: Boolean, default: () => true}
})
const opened = toRef(props, 'open');

onMounted(() => refresh())
</script>
<style scoped>
.terms dl {
clear: both;
  /*float: left;*/
  /*padding-left: 0px;*/
  /*width*/
}

.terms dt, .terms dd {
  float: left;
  @apply w-1/2;
}

.terms dt:last-child, .terms dd:last-child {
  clear:right;
}
</style>
