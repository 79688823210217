<template>
  <div class="NewsletterSendgridFrom">
    <template v-if="!showForm || loading">
      <button type="button" class=" mt-5 btn bg-primary text-white hover:bg-green-200 hover:text-gray-700" @click.prevent="loading = true; showForm = true; ">
        <Spinner v-if="loading" color="white" inline/>
        Je veux m'inscrire à la newsletter
      </button>
    </template>
    <div class="relative w-full text-center" v-if="showForm">
      <iframe
          :class="{'blur-sm': loading}"
          @loadstart="loading = true"
          @load="loading = false"
          width="100%"
          style="height: 650px;"
          frameborder="noborder"
          src="https://cdn.forms-content.sg-form.com/862b262c-c9f4-11ec-b6a8-02dda57a0e85"
      />
    </div>
  </div>
</template>

<script setup>
import {computed, ref, toRef} from "vue";
import Spinner from "~/components/Spinner.vue";

const props = defineProps({
  showForm: { type: Boolean, default: () => false}
})
const loading = ref(false);
const showForm = toRef(props, 'showForm');

</script>
<style>

iframe {
  font-family: Avenir, Helvetica, Arial, sans-serif !important;
}
</style>
