<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="text-lg max-w-prose mx-auto">
      <h1
          class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
        Mentions légales</h1><!--v-if-->
      </div>
    <div class="mt-6 prose prose-indigo prose-base dark:prose-invert text-gray-500 mx-auto">
      <div class="editorjs-vue-renderer">
        <div data-version="2.23.2" data-content-version="2.23.2" data-time="1654181447706"
             data-rendered-at="2022-08-10T18:07:43+02:00">
          <div class=" mx-auto">
            <article id="editor">
              <h2 class="mb-0">Éditeur du site</h2>
              <p>
                au-vrac.fr est un site internet conçu et réalisé par la société ISHA SARL en 2022.<br>
                Le siège social de ISHA SARL se situe à l'adresse suivante :
              </p>
              <address>
                AU VRAC <br>
                17 rue du docteur jean lissar <br>
                64240 Hasparren, France
              </address>
              <p class="mt-2">
                ISHA est une <abbr title="Société à Responsabilité Limitée">SARL</abbr> immatriculée au RCS de Bayonne sous le numéro SIRET : 912531845&nbsp;00010<br>
                Le numéro de TVA est le suivant : FR49912531845<br>
                AU VRAC est une enseigne commerciale de la société ISHA SARL.<br>
              </p>
            </article>
            <article id="hosting">

              <h2 class="mb-0">Hébergement</h2>
              <p>
                Le site au-vrac.fr est hébergé sur un serveur de la société Infomaniak.<br>
                L'ensemble de données sont stockées sur un serveur appartenant à Infomaniak et situées dans un centre de données en Suisse.<br>
                Infomaniak garantit la localisation des données en Suisse. L’ensemble des données transmises par le Client à Infomaniak sont stockées et hébergées exclusivement dans des centres de données qui sont la propriété d’Infomaniak, lesquels sont basés en Suisse.
              </p>
              <p>
                Identité complète de l'hébergeur :<br>
                <strong>Infomaniak Network SA</strong> est une société de droit suisse basée à Genève ;<br>
              </p>
              <address>
                Infomaniak Network SA - Rue Eugène-Marziano 25, 1227 Genève, Suisse

              </address>
              <p>
                N° IDE & TVA : CHE-103.167.648
              </p>
            </article>
            <article id="policy">
              <h2 class="mb-0">Avis de confidentialité</h2>
              <h3 class="=mb-0">À propos des traceurs (cookies et technologies similaires)</h3>
              <p>Les cookies sont de petits fichiers de données qui permettent à un site web de collecter et de
                stocker une gamme de données sur votre ordinateur de bureau, votre ordinateur portable ou votre appareil
                mobile.</p>
              <p>Au Vrac Hasparren n'utilise pas de cookies</p>
              <h3 class="=mb-0">Liens vers d’autres sites web</h3>
              <p>Notre site internet peut contenir des liens vers d’autres sites Internet exploités par d’autres
                organisations qui disposent de leurs propres avis de confidentialité. Lisez attentivement les conditions
                et l’avis de confidentialité avant de communiquer vos données à caractère personnel sur le site web d’une
                autre organisation, puisque nous déclinons toute responsabilité concernant les sites web d’autres
                organisations.</p>

            </article>

            <p>
              Dernière mise à jour le 10 août 2022 à 19h12
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
